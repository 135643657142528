import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MapsUgcRoundedIcon from "@mui/icons-material/MapsUgcRounded";
import MoreVert from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useAgentProfile } from "@src/appV2/Agents/api/useAgentProfile";
import { useToast } from "@src/appV2/lib";
import { parseISO } from "date-fns";
import pluralize from "pluralize";
import { type MouseEvent, useState } from "react";

import { useDeleteCommentReaction } from "../../api/useDeleteCommentReaction";
import { usePatchComment } from "../../api/usePatchComment";
import { usePostCommentReaction } from "../../api/usePostCommentReaction";
import { type WorkplaceCommentType } from "../../api/useWorkplaceComments";
import { Reaction } from "../../types";
import { formatDiffBetweenDates } from "../../utils";

interface CommentCardProps {
  workplaceId: string;
  comment: WorkplaceCommentType;
  onReply?: () => void;
  onUpdate: (comment: WorkplaceCommentType) => void;
}

export function CommentCard(props: CommentCardProps) {
  const {
    comment: { id: commentId, attributes: commentAttributes },
    workplaceId,
    onReply,
    onUpdate,
  } = props;
  const { showErrorToast, showSuccessToast } = useToast();
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement>();
  const isMenuOpen = isDefined(menuAnchorElement);

  const { data: agentProfile } = useAgentProfile();
  const { mutate: postReaction, isLoading: reactionIsPosting } = usePostCommentReaction(
    { workplaceId, commentId },
    {
      onSuccess: ({ data }) => {
        onUpdate(data);
      },
      onError: () => {
        showErrorToast("Something went wrong while posting reaction");
      },
    }
  );
  const { mutate: deleteReaction, isLoading: reactionIsDeleting } = useDeleteCommentReaction(
    { workplaceId, commentId },
    {
      onSuccess: ({ data }) => {
        onUpdate(data);
      },
      onError: () => {
        showErrorToast("Something went wrong while deleting reaction");
      },
    }
  );
  const { mutate: patchComment, isLoading: commentIsUpdating } = usePatchComment(
    { workplaceId, commentId },
    {
      onSuccess: ({ data }) => {
        showSuccessToast("Your review is now anonymous");
        onUpdate(data);
      },
      onError: () => {
        showErrorToast("Something went wrong while updating the review");
      },
    }
  );

  return (
    <Card sx={{ borderRadius: 0, width: "100%" }}>
      <CardActionArea disableRipple disabled={!isDefined(onReply)} onClick={onReply}>
        <CardHeader
          title={
            <Text bold variant="body2">
              {commentAttributes.workerInfo.name}
              {isDefined(commentAttributes.workerInfo.qualification)
                ? `, ${commentAttributes.workerInfo.qualification}`
                : ""}
            </Text>
          }
          sx={{
            paddingX: 1,
            paddingY: 0,
            ".MuiCardHeader-action": {
              alignSelf: "center",
            },
          }}
          action={
            <Text variant="body2">
              {formatDiffBetweenDates(new Date(), parseISO(commentAttributes.createdAt))}
            </Text>
          }
        />
        <CardContent sx={{ padding: 1 }}>
          <Text>{commentAttributes.text}</Text>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: 0,
          ">:not(:first-of-type)": {
            marginLeft: 0.25,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton
            color="inherit"
            disabled={reactionIsPosting || reactionIsDeleting}
            onClick={() => {
              if (commentAttributes.workerReaction) {
                deleteReaction();
              } else {
                postReaction({ reaction: Reaction.LIKE });
              }
            }}
          >
            {commentAttributes.workerReaction === Reaction.LIKE ? (
              <FavoriteIcon aria-label="Unlike This Comment" />
            ) : (
              <FavoriteBorderIcon aria-label="Like This Comment" />
            )}
          </IconButton>
          {isDefined(onReply) && (
            <IconButton
              aria-label="Reply to This Comment"
              color="inherit"
              disabled={!isDefined(onReply)}
              onClick={onReply}
            >
              <MapsUgcRoundedIcon
                sx={{
                  transform: "rotate(270deg)",
                  "path:last-child": {
                    fill: "none",
                  },
                }}
              />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {isDefined(commentAttributes.workerInfo.id) &&
            commentAttributes.workerInfo.id === agentProfile?.userId && (
              <IconButton
                color="inherit"
                disabled={commentIsUpdating}
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  setMenuAnchorElement(event.currentTarget);
                }}
              >
                <MoreVert aria-label="Open Menu For This Comment" />
              </IconButton>
            )}
        </Box>
        <Box>
          {commentAttributes.aggregates.totalReplies > 0 && (
            <Button
              size="small"
              sx={{
                fontWeight: (theme) => theme.typography.fontWeightRegular,
              }}
              disabled={!isDefined(onReply)}
              onClick={onReply}
            >
              {pluralize("reply", commentAttributes.aggregates.totalReplies, true)}
            </Button>
          )}
          {commentAttributes.aggregates.totalLikes > 0 && (
            <Button
              disabled
              size="small"
              variant="text"
              sx={{
                minWidth: "unset",
                fontWeight: (theme) => theme.typography.fontWeightRegular,
              }}
            >
              {pluralize("like", commentAttributes.aggregates.totalLikes, true)}
            </Button>
          )}
        </Box>
      </CardActions>
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorElement}
        open={isMenuOpen}
        onClose={() => {
          setMenuAnchorElement(undefined);
        }}
      >
        <MenuItem
          aria-label="Make Comment Anonymous"
          onClick={() => {
            patchComment({ anonymous: true });
            setMenuAnchorElement(undefined);
          }}
        >
          Make anonymous
        </MenuItem>
      </Menu>
    </Card>
  );
}
